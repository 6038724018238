@use "/src/styles/variables/dims.scss";
@use "/src/styles/variables/colors.scss";
@use "/src/styles/variables/typography.scss";
@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";

.root {
}

.gridContainer {
  background-color: colors.$darkness;
  color: colors.$white;
  height: 100%;
  padding: theme.spacing(2);
  width: 100vw;
}

.footer {
  border-bottom-left-radius: theme.border-radius(5);
  border-bottom-right-radius: theme.border-radius(5);

  &__footerText {
    font-size: 0.75rem;
  }
}

.previewBackground {
  background: colors.$background url(/img/stats-bg.png);
  background-position: 16% -72px;
  background-size: 600px;
  color: colors.$darkness;

  @include breakpoints.up("sm") {
    background-position: -10px -125px;
    background-size: cover;
  }

  .subtext a {
    color: colors.$primary;
  }
}

.wrapper {
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.logoBtn {
  border-radius: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: theme.spacing(0, auto, 4);
  max-width: 64px;
  overflow: hidden;
  padding: 0;

  &__svg {
    display: block;
    height: auto;
    width: 100%;
  }
}

.googleBtn {
  margin: theme.spacing(5, auto);
  max-width: 360px;
  transform: scale(1);
  transition: transform 0.3s ease;
  width: 100%;

  &:hover {
    transform: scale(1.02);
  }
}

.ssoButton {
  color: colors.$white;
  padding: theme.spacing(1, 2);
  margin-bottom: theme.spacing(1);

  &:hover {
    border-color: colors.$white;
  }
}

.subtext {
  margin: theme.spacing(1, 0);

  & a {
    color: colors.$shrimp;
  }
}

.sso {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: theme.spacing();
  margin: theme.spacing(3, auto);

  @include breakpoints.up("sm") {
    flex-direction: row;
  }

  &__input {
    background-color: colors.$white;
    border-radius: theme.border-radius(6);
    width: 300px;

    @include breakpoints.up("sm") {
      width: 280px;
    }
  }

  &__btn {
    padding: theme.spacing(2, 2.25);
    width: 300px;

    @include breakpoints.up("sm") {
      width: auto;
    }
  }
}

.alert {
  margin: theme.spacing(2, auto);
}
